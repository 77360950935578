import { IconButton, TextField, TextFieldProps } from "@redsift/design-system";
import { mdiEye, mdiEyeOff } from "@redsift/icons";
import React, { useState } from "react";

const PasswordField = (props: Omit<TextFieldProps, "ref">) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      label="Password"
      type={showPassword ? "text" : "password"}
      style={{ borderRadius: "4px" }}
      after={
        <IconButton
          icon={showPassword ? mdiEyeOff : mdiEye}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        />
      }
    />
  );
};

export default PasswordField;
