import { PAGES } from "@libs/web/urls";
import { Checkbox, Flexbox, Text, useId } from "@redsift/design-system";
import * as EmailValidator from "email-validator";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { checkPasswordStrength } from "../../libs/password";
import EmailField from "./EmailField";
import LoginButton from "./LoginButton";
import PasswordErrors from "./PasswordErrors";
import PasswordField from "./PasswordField";

interface EmailLoginProps {
  isSignUp: boolean;
  onSubmit: Function;
  setTermsFunction?: Function;
  agreedToTerms?: boolean;
  setAgreedToTerms?: Dispatch<SetStateAction<boolean>>;
}

const EmailLogin = ({
  isSignUp,
  onSubmit,
  agreedToTerms,
  setAgreedToTerms,
}: EmailLoginProps) => {
  const router = useRouter();
  const { user = "" } = router.query;
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [email, setEmail] = useState(user.toString());
  const [password, setPassword] = useState("");
  const [checkboxId] = useId();

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        onSubmit(email.trim(), password);
      }}
    >
      <Flexbox flexDirection="column" alignItems="center">
        <EmailField
          value={email}
          onChange={(value) => setEmail(value as string)}
          width="100%"
        />
        <PasswordField
          onChange={(value) => {
            const errors = checkPasswordStrength(value as string);
            setPasswordErrors(errors);
            setPassword(value as string);
          }}
          value={password}
          isInvalid={isSignUp && passwordErrors.length > 0}
          width="100%"
        />
        {isSignUp && passwordErrors.length > 0 && (
          <PasswordErrors errors={passwordErrors} />
        )}
        {!isSignUp && (
          <Link
            href={{
              pathname: PAGES.forgotpassword,
              query: {
                ...router.query,
              },
            }}
          >
            Forgot password?
          </Link>
        )}
        {isSignUp && setAgreedToTerms ? (
          <Flexbox flexDirection="row">
            <Checkbox
              isSelected={agreedToTerms}
              onChange={(isChecked) => setAgreedToTerms(isChecked)}
              aria-labelledby={checkboxId}
            />
            <Text id={checkboxId}>
              I have read and agree to the{" "}
              {[
                { label: "terms of service", url: "https://redsift.com/terms" },
                { label: "privacy policy", url: "https://redsift.com/privacy" },
                { label: "cookie policy", url: "https://redsift.com/cookies" },
              ].map(({ label, url }, index) => (
                <Fragment key={url}>
                  <Link href={url} target="_blank">
                    {label}
                  </Link>
                  {index < 2 ? ", " : null}
                </Fragment>
              ))}
              .
            </Text>
          </Flexbox>
        ) : null}
        <LoginButton
          isDisabled={
            isSignUp
              ? email === "" ||
                !EmailValidator.validate(email?.trim()) ||
                passwordErrors.length > 0 ||
                !agreedToTerms
              : email === "" ||
                !EmailValidator.validate(email?.trim()) ||
                password.length === 0
          }
          type="submit"
          provider="email"
          isSignUp={isSignUp}
          fullWidth
        />
        <Text color="grey">No credit card required</Text>
      </Flexbox>
    </form>
  );
};

export default EmailLogin;
