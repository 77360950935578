import { FirebaseError } from "@firebase/util";
import { PAGES } from "@libs/web/urls";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  OAuthProvider,
  onAuthStateChanged,
  SignInMethod,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import log from "loglevel";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect } from "react";
import { useQueryClient } from "react-query";

import { hash } from "@libs/api/hash-utils/hash";
import { FEDERATED_LOGIN_PROVIDERS } from "@libs/constants";
import { signUserIn } from "@libs/firebase/client-sdk-utils";
import { getProductName } from "@libs/products";
import { Flexbox, Heading, Icon, Link, Text } from "@redsift/design-system";
import { mdiLockOutline } from "@redsift/icons";
import { stringify } from "querystring";
import styled from "styled-components";
import { AlertContext } from "../contexts/AlertContext";
import { AppContext } from "../contexts/AppContext";
import EmailLogin from "./EmailLogin";
import LoginButton from "./LoginButton";

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
`;

const LoginCard: React.FC<{
  setPendingCredential: Function;
  isSmallScreen?: boolean;
}> = ({ setPendingCredential, isSmallScreen }) => {
  const ctx = useContext(AppContext);
  const ctxAlert = useContext(AlertContext);
  const auth = getAuth();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { tenantId = "", user = "", provider = "" } = router.query;
  // Providers
  const providers = FEDERATED_LOGIN_PROVIDERS(tenantId.toString());
  const providerLogIn = useCallback(
    async (provider: any) => {
      const { user, error } = await signUserIn(
        auth,
        provider,
        tenantId?.toString()
      );
      if (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
          const pCredential: any = OAuthProvider.credentialFromError(error);
          if (pCredential?.signInMethod !== SignInMethod.EMAIL_PASSWORD) {
            setPendingCredential(pCredential);
            ctx.setLoading(false);
            ctxAlert.setAlert(
              "Please log in using the same email address and password so that we can confirm and link your identity."
            );
          } else {
            ctx.setLoading(false);
            ctxAlert.setAlert((error as FirebaseError).code, "error");
          }
        } else {
          ctx.setLoading(false);
          ctxAlert.setAlert((error as FirebaseError).code, "error");
        }
      }
    },
    [auth, tenantId, setPendingCredential, ctx, ctxAlert]
  );
  useEffect(() => {
    const authFunc = async () => {
      ctx.setLoading(true);
      const providerObject: any = providers.find((p) => p.value === provider);
      const instance: any = providerObject?.get().setCustomParameters({
        login_hint: user.toString(),
      });
      const resp = await signInWithPopup(auth, instance);
      return resp;
    };
    if (user || provider) {
      // Need this logic to control if user in query param is different that the one already signin
      // to signout and clear cookie from other user
      const unregisterAuthObserver = onAuthStateChanged(
        auth,
        async (userFirebase) => {
          if (userFirebase && user && user !== userFirebase.email) {
            auth
              .signOut()
              .then(async () => {
                // Clear jwt cookie
                await fetch("/api/logout");
                await router.replace({
                  pathname: PAGES.login,
                  query: {
                    ...router.query,
                  },
                });
              })
              .finally(() => {
                // clear all the queries and info from cache
                queryClient.removeQueries();
                ctx.setLoading(false);
              });
          } else if (provider) {
            authFunc();
          }
          unregisterAuthObserver();
        }
      );
    }
  });

  return (
    <Flexbox flexDirection="column">
      <Heading as="h2" fontSize="24px" fontWeight="500" lineHeight="28px">
        Log in to {getProductName(ctx.getProduct())}
      </Heading>
      <Flexbox flexDirection="row" flexWrap="wrap" gap="6px">
        {providers.map((provider, i) => {
          const { value, enableSignup } = provider;
          if (!enableSignup) {
            return null;
          }
          return (
            <LoginButton
              key={i}
              provider={value}
              isSignUp={false}
              onClick={async () => await providerLogIn(provider?.get())}
            />
          );
        })}
      </Flexbox>
      {!tenantId && (
        <>
          <Flexbox flexDirection="row" alignItems="center" gap="12px">
            <Divider />
            <Text color="grey">or</Text>
            <Divider />
          </Flexbox>
          <EmailLogin
            isSignUp={false}
            onSubmit={async (email: string, password: string) => {
              try {
                const hashPass = await hash(password);
                ctx.setLoading(true);
                const creds = await signInWithEmailAndPassword(
                  auth,
                  email,
                  hashPass
                );
              } catch (error) {
                ctx.setLoading(false);
                let msg = (error as FirebaseError).code;
                log.debug("LoginCard::error:", error);
                if (msg === "auth/invalid-login-credentials") {
                  msg = `Incorrect username or password. Please follow the "forgot password" or "sign up" links below.`;
                } else if (msg === "auth/wrong-password") {
                  msg = `Incorrect username or password. Please follow the "forgot password" or "sign up" links below.`;
                  const loginMethods = await fetchSignInMethodsForEmail(
                    auth,
                    email
                  );
                  if (!loginMethods.includes("password")) {
                    const provider = loginMethods.includes("google.com")
                      ? "Google"
                      : loginMethods.includes("microsoft.com")
                      ? "Microsoft"
                      : "";
                    if (provider) {
                      msg = `To sign in with this email address, please use the "Sign in with ${provider}" button.`;
                    }
                  }
                } else if (msg === "auth/user-not-found") {
                  msg = `Incorrect username or password. Please follow the "forgot password" or "sign up" links below.`;
                }
                ctxAlert.setAlert(msg, "error");
              }
            }}
          />
        </>
      )}
      <Flexbox flexDirection="column" marginTop="12px" gap="4px">
        <Text>
          {"Don't have an account? "}
          <b>
            <Link
              as={NextLink}
              href={`${PAGES.signup}?${stringify(router.query)}`}
            >
              Sign up
            </Link>
          </b>
        </Text>
        <Divider />
        <Flexbox
          flexDirection="row"
          padding="20px"
          background="#DCE8F3"
          style={{ borderRadius: "10px" }}
          gap="20px"
          marginTop="12px"
        >
          <Icon icon={mdiLockOutline} color="primary" />
          <Flexbox flexDirection="column" gap="12px">
            <Text fontSize="16px" lineHeight="24px" fontWeight="600">
              Privacy protection
            </Text>

            <Text>
              Red Sift is committed to keeping your data protected. See details
              in our{" "}
              <Link href="https://redsift.com/privacy" target="_blank">
                Privacy Policy
              </Link>
              .
            </Text>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export default LoginCard;
