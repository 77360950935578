import { TextField, TextFieldProps } from "@redsift/design-system";

const EmailField = (props: Omit<TextFieldProps, "ref">) => {
  return (
    <TextField
      {...props}
      label="Email address"
      style={{ borderRadius: "4px" }}
    />
  );
};

export default EmailField;
