import { FirebaseError } from "@firebase/util";
import { hash } from "@libs/api/hash-utils/hash";
import { FEDERATED_LOGIN_PROVIDERS } from "@libs/constants";
import { PAGES } from "@libs/web/urls";
import { Flexbox, Heading, Icon, Link, Text } from "@redsift/design-system";
import { mdiLockOutline } from "@redsift/icons";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { stringify } from "querystring";
import { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { signUserIn } from "../../libs/firebase/client-sdk-utils";
import { getProductName } from "../../libs/products";
import { AlertContext } from "../contexts/AlertContext";
import { AppContext } from "../contexts/AppContext";
import EmailLogin from "./EmailLogin";
import LoginButton from "./LoginButton";

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
`;

// Providers
const providers = FEDERATED_LOGIN_PROVIDERS();

const SignupCard: React.FC<{ isSmallScreen?: boolean }> = ({
  isSmallScreen,
}) => {
  const auth = getAuth();
  const ctx = useContext(AppContext);
  const ctxAlert = useContext(AlertContext);
  const router = useRouter();
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const providerLogIn = useCallback(
    async (provider: any) => {
      if (!agreedToTerms) {
        ctxAlert.setAlert("You need to accept our legal terms.", "error");
        return;
      }
      ctx.setLoading(true);
      const { user, error } = await signUserIn(auth, provider);
      if (error) {
        ctx.setLoading(false);
        ctxAlert.setAlert((error as FirebaseError).code, "error");
      }
    },
    [ctx, ctxAlert, auth, agreedToTerms]
  );

  return (
    <Flexbox flexDirection="column">
      <Heading as="h2" fontSize="24px" fontWeight="500" lineHeight="28px">
        Sign up to {getProductName(ctx.getProduct())}
      </Heading>
      <Flexbox flexDirection="row" flexWrap="wrap" gap="6px">
        {providers.map((provider, i) => {
          const { value, enableSignup } = provider;
          if (!enableSignup) {
            return null;
          }
          return (
            <LoginButton
              key={i}
              provider={value}
              isSignUp={true}
              onClick={async () => await providerLogIn(provider?.get())}
            />
          );
        })}
      </Flexbox>
      <Flexbox flexDirection="row" alignItems="center" gap="12px">
        <Divider />
        <Text color="grey">or</Text>
        <Divider />
      </Flexbox>

      <EmailLogin
        isSignUp={true}
        agreedToTerms={agreedToTerms}
        setAgreedToTerms={setAgreedToTerms}
        onSubmit={async (email: string, password: string) => {
          if (!agreedToTerms) {
            ctxAlert.setAlert("You need to accept our legal terms.", "error");
            return;
          }
          try {
            ctx.setLoading(true);
            const hashPass = await hash(password);
            await createUserWithEmailAndPassword(auth, email, hashPass);
          } catch (error) {
            ctx.setLoading(false);
            ctxAlert.setAlert((error as FirebaseError).code, "error");
          }
        }}
      />

      <Flexbox flexDirection="column" marginTop="12px" gap="4px">
        <Text>
          Already a user?{" "}
          <b>
            <Link
              as={NextLink}
              href={`${PAGES.login}?${stringify(router.query)}`}
            >
              Sign in
            </Link>
          </b>
        </Text>
        <Divider />
        <Flexbox
          flexDirection="row"
          padding="20px"
          background="#DCE8F3"
          style={{ borderRadius: "10px" }}
          gap="20px"
          marginTop="12px"
        >
          <Icon icon={mdiLockOutline} color="primary" />
          <Flexbox flexDirection="column" gap="12px">
            <Text fontSize="16px" lineHeight="24px" fontWeight="600">
              Privacy protection
            </Text>

            <Text>
              Red Sift is committed to keeping your data protected. See details
              in our{" "}
              <Link href="https://redsift.com/privacy" target="_blank">
                Privacy Policy
              </Link>
              .
            </Text>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export default SignupCard;
