import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface PasswordErrorList {
  errors: string[];
}

const PasswordErrors = ({ errors = [] }: PasswordErrorList) => {
  return (
    <Stack sx={{ width: "100%" }}>
      {errors.map((e, i) => (
        <Typography key={i} variant="caption" sx={{ color: "#ff0000" }}>
          {e}
        </Typography>
      ))}
      {errors.length ? (
        <Typography variant="caption" sx={{ color: "#ff0000" }}>
          Or be a{" "}
          <a
            href="https://imgs.xkcd.com/comics/password_strength.png"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#ff0000", fontSize: "0.75rem" }}
          >
            passphrase
          </a>{" "}
          (20 characters)
        </Typography>
      ) : null}
    </Stack>
  );
};

export default PasswordErrors;
