import LoginScreen from "@components/common/LoginScreen";
import SmallLoginScreen from "@components/common/SmallLoginScreen";
import {
  Flexbox,
  Heading,
  Spinner,
  useWindowSize,
} from "@redsift/design-system";
import LoginCard from "./LoginCard";
import SignupCard from "./SignupCard";

const LoginPage = ({
  isSignUp = false,
  setPendingCredential,
  isLoading = false,
}: {
  isSignUp: boolean;
  setPendingCredential: Function;
  isLoading?: boolean;
}) => {
  const { width } = useWindowSize();
  const isSmallScreen = width && width <= 860;

  let LoginPanel = isSignUp ? (
    <SignupCard isSmallScreen={!!isSmallScreen} />
  ) : (
    <LoginCard
      isSmallScreen={!!isSmallScreen}
      setPendingCredential={setPendingCredential}
    />
  );
  if (isLoading) {
    LoginPanel = (
      <Flexbox
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Spinner size="large" />
        <Heading as="h3">Logging in...</Heading>
      </Flexbox>
    );
  }

  return isSmallScreen ? (
    <SmallLoginScreen LoginPanel={LoginPanel} />
  ) : (
    <LoginScreen LoginPanel={LoginPanel} />
  );
};

export default LoginPage;
